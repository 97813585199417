<template>
  <!--  import instruction-->
  <!--  copy and paste div.container-->
  <!-- replace <a href> with uncomment router-link-->

  <nav class="navbar navbar-light navbar-expand bg-white shadow mb-4 topbar static-top">
    <div class="container-fluid"><button class="btn btn-link d-md-none rounded-circle me-3" id="sidebarToggleTop-1" type="button"><i class="fas fa-bars"></i></button>
      <ul class="navbar-nav flex-nowrap ms-auto">
        <li class="nav-item dropdown no-arrow">
          <div class="nav-item dropdown no-arrow"><a class="dropdown-toggle nav-link" aria-expanded="false" data-bs-toggle="dropdown" href="#"><img class="img-locale" :src="locale" width="" height=""></a>
            <div class="dropdown-menu shadow dropdown-menu-end animated--grow-in">
              <router-link to="/admin/en/" class="dropdown-item" :class="{'active':isEnglish}" @click="update('en')">English</router-link>
<!--              <a class="dropdown-item" href="/admin/en/system/" v-on:click="update('en')">English</a>-->
              <router-link to="/admin/ru/" class="dropdown-item" :class="{'active':isRussian}" @click="update('ru')">Русский</router-link>
<!--              <a class="dropdown-item" href="/admin/ru/system/" v-on:click="update('ru')">Русский</a>-->
            </div>
          </div>
        </li>
        <li class="nav-item dropdown no-arrow">
          <div class="nav-item dropdown no-arrow"><a class="dropdown-toggle nav-link" aria-expanded="false" data-bs-toggle="dropdown" href="#"><span class="d-none d-lg-inline me-2 text-gray-600 small">Admin</span><img class="border rounded-circle img-profile" :src="avatar" width="" height=""></a>
            <div class="dropdown-menu shadow dropdown-menu-end animated--grow-in"><a class="dropdown-item" href="#"><i class="fas fa-user fa-sm fa-fw me-2 text-gray-400"></i>&nbsp;Profile</a><a class="dropdown-item" href="#"><i class="fas fa-cogs fa-sm fa-fw me-2 text-gray-400"></i>&nbsp;Settings</a>
              <div class="dropdown-divider"></div><a class="dropdown-item" href="#" v-on:click.prevent="logout"><i class="fas fa-sign-out-alt fa-sm fa-fw me-2 text-gray-400"></i>&nbsp;Logout</a>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </nav>

</template>

<script>
import {ref, computed, onMounted, watch, inject} from 'vue'
import {useStore} from 'vuex'
import {useRoute, useRouter} from 'vue-router'
import ICON_ADMIN from '@/export/assets/img/avatar-admin-light.jpg'
import ICON_LOCALE_EN from '@/export/assets/img/united-kingdom.png'
import ICON_LOCALE_RU from '@/export/assets/img/russia.png'
import {useAdminLoading} from "@/use/admin-module";
import {LOCALE_ENGLISH, LOCALE_RUSSIAN,} from "@/use/constants";

export default {
  name: "TheNavbar",
  setup() {

    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    const avatar = computed(() => ICON_ADMIN)
    const locale = computed(() => store.getters['admin/locale'] === LOCALE_ENGLISH ? ICON_LOCALE_EN : ICON_LOCALE_RU)
    const isEnglish = computed(() => LOCALE_ENGLISH === store.getters['admin/locale'])
    const isRussian = computed(() => LOCALE_RUSSIAN === store.getters['admin/locale'])

    onMounted(async () => {
      try {
        update(route?.meta?.locale)

      } catch (e) {
        console.dir(e)
      }

    })

    const update = (locale) => {
      store.commit('admin/locale', locale)
    }

    const logout = async () => {
      try {
        loading(true)
        const response = await store.dispatch('auth/logout')
        if (response?.error) {
          return
        }
        await router.push('/login')

      } catch (e) {
        console.dir(e)
      }
      loading(false)
    }

    const loading = useAdminLoading()
    return {
      avatar, locale, isEnglish, isRussian,
      update, logout,
    }
  },
  components: {},
}
</script>

<style scoped>

</style>