<template>
  <!--  import instruction-->
  <!--  copy and paste div.container-->

  <footer id="footer" class="section-footer">
    <div class="footer fixed">
      <div class="row g-0">
        <div class="col-md-8 col-lg-9 align-self-center footer-col">
          <div class="d-inline-flex text-14-black" v-if="copyright">
            <p><i class="far fa-copyright"/></p>
            <span>&nbsp;</span>
            <p v-html="copyright"></p>
            <the-protected-email :email="contact?.email" :type="CONTACT_EMAIL_TYPE_TEXT" v-if="contact?.email"></the-protected-email>
          </div>
        </div>
        <div class="col text-start text-md-end align-self-center footer-col">
          <div class="social-icons">
            <a :href="contact?.linkedin" target="_blank"><i class="fab fa-linkedin-in"></i></a>
            <a class="d-inline-flex social-icons-font" :href="contact?.hh" target="_blank">
              <div class="width">
                <div class="scale"><span>hh</span></div>
              </div>
            </a>
            <a :href="contact?.github" target="_blank"><i class="fab fa-github"></i></a>
            <a :href="contact?.telegram" target="_blank"><i class="fab fa-telegram"></i></a>
            <the-protected-email :email="contact?.email" :type="CONTACT_EMAIL_TYPE_ICON"></the-protected-email>
          </div>
        </div>
      </div>
    </div>
  </footer>

</template>

<script>
import {ref, computed, onMounted, watch, inject} from 'vue'
import {useStore} from 'vuex'
import {useRouter} from 'vue-router'
import TheProtectedEmail from "@/components/public/app/main/TheProtectedEmail";
import {CONTACT_EMAIL_TYPE_TEXT, CONTACT_EMAIL_TYPE_ICON, EMPTY_STRING, COPYRIGHT_TEXT_PREFIX} from "@/use/constants";

export default {
  name: "TheMainFooter",
  props: {
    contact: {
      type: Object,
      required: true,
    },
    copyright: {
      type: String,
      required: true,
    },
  },
  setup(props) {

    const store = useStore()
    const router = useRouter()

    const loading = ref('true')
    const type = CONTACT_EMAIL_TYPE_TEXT
    const prefix = COPYRIGHT_TEXT_PREFIX

    onMounted(async () => {
      try {

      } catch (e) {
        console.dir(e)
      }

      loading.value = false
    })


    return {
      loading, type, prefix, CONTACT_EMAIL_TYPE_TEXT, CONTACT_EMAIL_TYPE_ICON,
    }
  },
  components: {
    TheProtectedEmail,
  },
}
</script>

<style scoped>

</style>