<template>
  <!--  import instruction-->
  <!--  copy and paste div.container-->
  <!-- replace <a href> with uncomment router-link-->

  <nav class="navbar navbar-dark align-items-start sidebar sidebar-dark accordion bg-gradient-primary p-0">
    <div class="container-fluid d-flex flex-column p-0"><a class="navbar-brand d-flex justify-content-center align-items-center sidebar-brand m-0" href="#">
      <div class="d-md-flex align-items-md-center sidebar-brand-image"><img class="img-fluid bg-white border rounded-circle" src="@/export/assets/img/compass.png"></div>
      <div class="sidebar-brand-text mx-3"><span>Admin Panel</span></div>
    </a>
      <hr class="sidebar-divider my-0">
      <ul class="navbar-nav text-light" id="nav-sidebar">
        <li class="nav-item">
          <router-link class="nav-link" :to="`/admin/${locale}/system`"><i class="fas fa-home"></i><span>System</span></router-link>
<!--          <a class="nav-link" href="/admin/en/system"><i class="fas fa-home"></i><span>System</span></a>-->
        </li>
        <li class="nav-item">
          <router-link class="nav-link" :to="`/admin/${locale}/work`"><i class="fas fa-images"></i><span>Work</span></router-link>
<!--          <a class="nav-link" href="/admin/en/work"><i class="fas fa-images"></i><span>Work</span></a>-->
        </li>
        <li class="nav-item">
          <router-link class="nav-link" :to="`/admin/${locale}/about`"><i class="fas fa-info-circle"></i><span>About</span></router-link>
<!--          <a class="nav-link" href="/admin/en/about"><i class="fas fa-info-circle"></i><span>About</span></a>-->
        </li>
        <li class="nav-item">
          <router-link class="nav-link" :to="`/admin/${locale}/contacts`"><i class="fa fa-envelope"></i><span>Contacts</span></router-link>
<!--          <a class="nav-link" href="/admin/en/contacts"><i class="fa fa-envelope"></i><span>Contacts</span></a>-->
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/login"><i class="fa fa-envelope"></i><span>Login</span></router-link>
<!--          <a class="nav-link" href="/login"><i class="far fa-user-circle"></i><span>Login</span></a>-->
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/register"><i class="fa fa-envelope"></i><span>Register</span></router-link>
<!--          <a class="nav-link" href="/register"><i class="fas fa-user-circle"></i><span>Register</span></a>-->
        </li>
      </ul>
      <div class="text-center d-none d-md-inline"><button class="btn rounded-circle border-0" id="sidebarToggle" type="button"></button></div>
    </div>
  </nav>

</template>

<script>
import {ref, computed, onMounted, watch, inject} from 'vue'
import {useStore} from 'vuex'
import {useRoute, useRouter} from 'vue-router'

export default {
  name: "TheSidebar",
  setup() {

    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    const locale = computed(()=> store.getters['admin/locale'])

    onMounted(async () => {
      try {

      } catch (e) {
        console.dir(e)
      }


    })
    return {
      locale,
    }
  },
  components: {},
}
</script>

<style scoped>

</style>