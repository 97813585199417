<template>
  <span class="spinner-border  modal-spinner" role="status"></span>
</template>

<script>
import {ref, computed, onMounted, watch, inject} from 'vue'
import {useStore} from 'vuex'
import {useRouter} from 'vue-router'

export default {
  name: "TheSpinner",
  setup() {

    const store = useStore()
    const router = useRouter()

    const loading = ref('true')

    onMounted(async () => {
      try {

      } catch (e) {
        console.dir(e)
      }

      loading.value = false
    })
    return {
      loading,
    }
  },
  components: {},
}
</script>

<style scoped>

</style>