import {computed, ref} from "vue";
import ICON_BRAND from "@/export/assets/img/compass.png";
import {
    COPYRIGHT_TEXT_EN,
    COPYRIGHT_TEXT_RU,
    LOCALE_RUSSIAN,
    SEND_MODE_POST,
    SEND_MODE_PUT,
    EMPTY_STRING,
    LOCALE_ENGLISH, CLEAN_PATH_REGEX, EXTRACT_HOST_REGEX, TABLE_APP_MEDIA_LG, TABLE_APP_SCALE, TABLE_APP_SCALE_LG
} from "@/use/constants";
import store from "@/store";


export function useFooter(loaded=false) {
    if (loaded) {

        const copyright = ref('')
        const update = (value)=>{
            copyright.value = value
        }
        return {
            copyright,
            update,
        }
    } else {

        const copyright = computed(() => store.getters['main/locale'] === LOCALE_RUSSIAN ? COPYRIGHT_TEXT_RU : COPYRIGHT_TEXT_EN)
        const update = (value)=>{
        }
        return {
            copyright,
            update,
        }
    }
}

export function useNavbar(loaded=false) {
    if (loaded) {
        const brand = ref('')
        const update = (value)=>{
            brand.value = value
        }
        return {
            brand,
            update,
        }
    } else {
        const brand = computed(() => ICON_BRAND)
        const update = (value)=>{
        }
        return {
            brand,
            update,
        }
    }
}

export function useUpdate() {
    const send = async (url, data, mode = 'post') => {
        if (mode ===  SEND_MODE_POST) {
            return await store.dispatch(`request/post`, {
                'url': url,
                'data': data,
            })
        } else if (mode === SEND_MODE_PUT) {
            return await store.dispatch(`request/put`, {
                'url': `${url}/${record.value?.id}`,
                'data': data,
            })
        } else {
            return null
        }
    }
    return {
        send
    }
}
export function useMainTable(){

    const table = ref(null)
    const first = ref(true)
    const last = ref(true)
    const toggle = ref(false)

    /**
     *  shows arrows according to scroll position
     *  scrollWidth   actual image width
     *  offsetWidth   actual div width where image scrolled inside
     *
     */
    const scroll = () => {
        const offset = Math.ceil(table.value?.scrollLeft)
        const max = Math.ceil(table.value?.scrollWidth - table.value?.offsetWidth)

        first.value = offset < 5
        last.value = max > 0 ? offset >= max - 5 : true

    }

    /**
     *  moves image smoothly when click on arrows
     */
    const move = (forward) => {
        if (!table.value) {
            return
        }
        const width = window.innerWidth < TABLE_APP_MEDIA_LG ? window.innerWidth / TABLE_APP_SCALE : window.innerWidth / TABLE_APP_SCALE_LG
        const dx = (forward ? 1 : -1) * width

        table.value?.scrollTo(table.value.scrollLeft + dx, 0)
    }

    /**
     *  calls scroll after 250ms
     */
    const refresh = () => {
        if (toggle.value) {
            return
        }
        toggle.value = true;
        setTimeout(() => {
            scroll()
            toggle.value = false;
        }, 250)

    }

    return {
        table, first, last, scroll, move, refresh
    }
}


export function useMainUtil(){

    const locale = computed(() => {
        const locale = store.getters['main/locale'];
        if(!locale){
            return LOCALE_ENGLISH
        }
        return  store.getters['main/locale']
    })

    const loading = (value) => {
        store.commit('main/loading', value)
    }

    const scroll = ()=> {
        window.scrollTo(0, 0)
    }

    const protect = (value, object= {})=>{
        return value || object
    }

    const isEmpty = (obj)=> {
        if (!obj) {
            return true
        }
        return obj
            && Object.keys(obj).length === 0
            && Object.getPrototypeOf(obj) === Object.prototype
    }

    const extract = (regex, s) => {
        if (!regex || !s) {
            return EMPTY_STRING
        }

        if (!regex) {
            return EMPTY_STRING
        }
        regex.lastIndex = 0;      // !!!MUST HAVE reset regex before regex /g
        const value = regex.exec(s);
        if (!value || !value.length) {
            return EMPTY_STRING
        }
        return value[0]
    }

    const clean = (url) => {
        if (!url) return ''
        return  url.replace(CLEAN_PATH_REGEX, '')
    }

    const path = (url) => {
        if (!url) return ''
        const array = process.env.VUE_APP_URL.match(EXTRACT_HOST_REGEX)
        if (!array || array.length === 0) return ''
        const host = array[0]
        const path = url.replace(CLEAN_PATH_REGEX, '')
        return host + '/' + path
    }

    const shell = (value, strong) => {
        if (!value) return ''
        if (strong) {
            return '<b>' + value + '</b>'
        }
        return value
    }

    return {
        locale,
        loading, scroll, protect, isEmpty, extract, path, clean, shell,
    }
}

export function useMainLocale() {
    return computed(() => store.getters['main/locale'])
}

export function useMainLoading() {
    return (value) => {
        store.commit('main/loading', value)
    }
}

