import {createRouter, createWebHistory} from 'vue-router'
import Main from "@/components/public/Main";
import {CONTAINER_TYPE_ABOUT_3, CONTAINER_TYPE_ABOUT_4} from "@/use/constants";

const routes = [
    {
        path: '/about',
        name: 'main.en.about.alias',
        meta: {
            locale: 'en',
            parent: '/main/en/',
        },
        alias: [
            '/about/en', '/en/about'
        ],
        redirect: {
            name: 'main.en.about'
        }
    },
    {
        path: '/contacts',
        name: 'main.en.contacts.alias',
        meta: {
            locale: 'en',
            parent: '/main/en/',
        },
        alias: [
            '/contacts/en', '/en/contacts'
        ],
        redirect: {
            name: 'main.en.contacts'
        }
    },
    {
        path: '/about/ru',
        name: 'main.ru.about.alias',
        meta: {
            locale: 'ru',
            parent: '/main/ru/',
        },
        redirect: {
            name: 'main.ru.about'
        },
        alias: [
            '/ru/about'
        ],
    },
    {
        path: '/contacts/ru',
        name: 'main.ru.contacts.alias',
        meta: {
            locale: 'ru',
            parent: '/main/ru/',
        },
        alias: [
            '/ru/contacts'
        ],
        redirect: {
            name: 'main.ru.contacts'
        }
    },
    {
        path: '/main/en/',
        name: 'main.en',
        component: Main,
        meta: {
            class: 'body body-narrow',
            layout: 'main',
            auth: false,
        },
        redirect: {
            name: 'main.en.work'
        },
        alias: [
            '/', '/en'
        ],
        children: [
            {
                path: 'work',
                name: 'main.en.work',
                component: () => import('@/components/public/menu/MainWork'),
                meta: {
                    locale: 'en',
                    parent: '/main/en/',
                    child: '/main/en/design/',
                },
            },
            {
                path: 'design/:id?',
                name: 'main.en.design',
                component: () => import('../components/public/menu/work/MainDesign'),
                meta: {
                    locale: 'en',
                    parent: '/main/en/work/',
                },
            },
            {
                path: 'about',
                name: 'main.en.about',
                component: () => import('@/components/public/menu/MainAbout'),
                meta: {
                    locale: 'en',
                    parent: '/main/en/',
                    long: true,
                },
            },
            {
                path: 'contacts',
                name: 'main.en.contacts',
                component: () => import('@/components/public/menu/MainContacts'),
                meta: {
                    locale: 'en',
                    parent: '/main/en/',
                    long: true,
                },
            },
        ],
    },
    {
        path: '/main/ru/',
        name: 'main.ru',
        component: Main,
        meta: {
            class: 'body  body-narrow',
            layout: 'main',
            auth: false,
        },
        redirect: {
            name: 'main.ru.work'
        },
        alias: [
            '/ru'
        ],
        children: [
            {
                path: 'work',
                name: 'main.ru.work',
                component: () => import('@/components/public/menu/MainWork'),
                meta: {
                    locale: 'ru',
                    parent: '/main/ru/',
                    child: '/main/ru/design/',
                },
            },
            {
                path: 'design/:id?',
                name: 'main.ru.design',
                component: () => import('../components/public/menu/work/MainDesign'),
                meta: {
                    locale: 'ru',
                    parent: '/main/ru/work/',
                },
            },
            {
                path: 'about',
                name: 'main.ru.about',
                component: () => import('@/components/public/menu/MainAbout'),
                meta: {
                    locale: 'ru',
                    parent: '/main/ru/',
                    long: true,
                },
            },
            {
                path: 'contacts',
                name: 'main.ru.contacts',
                component: () => import('@/components/public/menu/MainContacts'),
                meta: {
                    locale: 'ru',
                    parent: '/main/ru/',
                    long: true,
                },
            },
        ]
    },
    {
        path: '/register',
        name: 'register',
        component: () => import('../components/public/auth/Register'),
        meta: {
            class: 'body bg-gradient-primary',
            layout: 'auth',
            auth: false,
        }
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../components/public/auth/Login'),
        meta: {
            class: 'body bg-gradient-primary',
            layout: 'auth',
            auth: false,
        }
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: () => import('../components/public/auth/ForgotPassword'),
        meta: {
            class: 'body bg-gradient-primary',
            layout: 'auth',
            auth: false,
        }
    },
    {
        path: '/admin/en/',
        name: 'admin.en',
        component: () => import('../components/secure/Admin'),
        meta: {
            class: 'body bg-gradient-primary',
            layout: 'admin',
            auth: true,
        },
        redirect: {
            name: 'admin.en.work'
        },
        alias: '/admin',
        children: [
            {
                path: 'system',
                name: 'admin.en.system',
                component: () => import('../components/secure/menu/AdminSystem'),
                meta: {
                    locale: 'en',
                    parent: '/admin/en/',
                },
            },
            {
                path: 'work',
                name: 'admin.en.work',
                component: () => import('../components/secure/menu/AdminWork'),
                meta: {
                    locale: 'en',
                    parent: '/admin/en/',
                    child: '/admin/en/design/',
                },
            },
            {
                path: 'design/:id?',
                name: 'admin.en.design',
                component: () => import('../components/secure/menu/work/AdminDesign'),
                meta: {
                    locale: 'en',
                    parent: '/admin/en/work/',
                    child: '/admin/en/section/',
                },
            },
            {
                path: 'section/:id?',
                name: 'admin.en.section',
                component: () => import('../components/secure/menu/work/AdminSection'),
                meta: {
                    locale: 'en',
                    parent: '/admin/en/design/',
                    child: '/admin/en/container/',
                },
            },
            {
                path: 'container/:id?',
                name: 'admin.en.container',
                component: () => import('../components/secure/menu/work/AdminContainer'),
                meta: {
                    locale: 'en',
                    parent: '/admin/en/section/',
                    child: '/admin/en/row/',
                },
            },
            {
                path: 'row/:id?',
                name: 'admin.en.row',
                component: () => import('../components/secure/menu/work/AdminRow'),
                meta: {
                    locale: 'en',
                    parent: '/admin/en/container/',
                },
            },
            {
                path: 'about/:id?',
                name: 'admin.en.about',
                component: () => import('../components/secure/menu/AdminAbout'),
                meta: {
                    locale: 'en',
                    parent: '/admin/en/',
                    child: '/admin/en/container/about/',
                    long: true,
                },
            },
            {
                path: 'container/about/:id?',
                name: 'admin.en.container.about',
                component: () => import('../components/secure/menu/about/containers/AdminContainerAbout'),
                meta: {
                    locale: 'en',
                    parent: '/admin/en/about/',
                    child: '/admin/en/row/about/',
                },
            },
            {
                path: 'row/about/:id?',
                name: 'admin.en.row.about',
                component: () => import('../components/secure/menu/work/AdminRow'),
                meta: {
                    locale: 'en',
                    parent: '/admin/en/container/about/',
                },
            },
            {
                path: 'contacts/:id?',
                name: 'admin.en.contacts',
                component: () => import('../components/secure/menu/AdminContacts'),
                meta: {
                    locale: 'en',
                    parent: '/admin/en/',
                },
            },
        ],
    },
    {
        path: '/admin/ru/',
        name: 'admin.ru',
        component: () => import('../components/secure/Admin'),
        meta: {
            class: 'body bg-gradient-primary',
            layout: 'admin',
            auth: true,
        },
        redirect: {
            name: 'admin.ru.work'
        },
        children: [
            {
                path: 'system',
                name: 'admin.ru.system',
                component: () => import('../components/secure/menu/AdminSystem'),
                meta: {
                    locale: 'ru',
                    parent: '/admin/ru/',
                },
            },
            {
                path: 'work',
                name: 'admin.ru.work',
                component: () => import('../components/secure/menu/AdminWork'),
                meta: {
                    locale: 'ru',
                    parent: '/admin/ru/',
                    child: '/admin/ru/design/'
                },
            },
            {
                path: 'design/:id?',
                name: 'admin.ru.design',
                component: () => import('../components/secure/menu/work/AdminDesign'),
                meta: {
                    locale: 'ru',
                    parent: '/admin/ru/work/',
                    child: '/admin/ru/section/'
                },
            },
            {
                path: 'section/:id?',
                name: 'admin.ru.section',
                component: () => import('../components/secure/menu/work/AdminSection'),
                meta: {
                    locale: 'ru',
                    parent: '/admin/ru/design/',
                    child: '/admin/ru/container/'
                },
            },
            {
                path: 'container/:id?',
                name: 'admin.ru.container',
                component: () => import('../components/secure/menu/work/AdminContainer'),
                meta: {
                    locale: 'ru',
                    parent: '/admin/ru/section/',
                    child: '/admin/ru/row/'
                },
            },
            {
                path: 'row/:id?',
                name: 'admin.ru.row',
                component: () => import('../components/secure/menu/work/AdminRow'),
                meta: {
                    locale: 'ru',
                    parent: '/admin/ru/container/',
                },
            },
            {
                path: 'about/:id?',
                name: 'admin.ru.about',
                component: () => import('../components/secure/menu/AdminAbout'),
                meta: {
                    locale: 'ru',
                    parent: '/admin/ru/',
                    child: '/admin/ru/container/about/',  // containers extended Laravel API or rows must be extended too
                    long: true,
                },
            },
            {
                path: 'container/about/:id?',
                name: 'admin.ru.container.about',
                component: () => import('../components/secure/menu/about/containers/AdminContainerAbout'),
                meta: {
                    locale: 'ru',
                    parent: '/admin/ru/about/',
                    child: '/admin/ru/row/about/',
                },
            },
            {
                path: 'row/about/:id?',
                name: 'admin.ru.row.about',
                component: () => import('../components/secure/menu/work/AdminRow'),
                meta: {
                    locale: 'ru',
                    parent: '/admin/ru/container/about/',
                },
            },
            {
                path: 'contacts/:id?',
                name: 'admin.ru.contacts',
                component: () => import('../components/secure/menu/AdminContacts'),
                meta: {
                    locale: 'ru',
                    parent: '/admin/ru/',
                },
            },
        ],
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    linkActiveClass: 'active',
    linkExactActiveClass: 'exact-active'
})

router.beforeEach((to, from) => {
    // if(to.meta?.class) {
    //     document.body.className = to.meta.class
    // }else {
    //     document.body.className = 'body'
    // }

})
export default router
