<template>
  <component :is="'layout-'+layout" v-if="layout"></component>
</template>

<script>
import {computed} from 'vue'
import {useRoute} from 'vue-router'
import LayoutAuth from "./components/layouts/LayoutAuth";
import LayoutAdmin from "./components/layouts/LayoutAdmin";
import LayoutMain from "./components/layouts/LayoutMain";

export default {
  name: "App",
  setup() {

    const route = useRoute();
    const layout = computed(() => route.meta?.layout)

    return {
      layout
    }
  },
  components: {
    LayoutAuth, LayoutAdmin, LayoutMain

  },
}
</script>

<style scoped>

</style>