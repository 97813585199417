export const EMPTY_STRING = '';

export const DEFAULT_STRING_LENGTH = 191;
export const DEFAULT_TABLE_TITLE_LENGTH = 64;
export const DEFAULT_TABLE_TITLE_REGEX = /<\/?[^>]+>/ig     //<\/?[^>]+>/ig

export const UPLOAD_HOST_REGEX =  /^[a-zA-Z]{3,5}:\/{2}[a-zA-Z0-9_.:-]+\//

export const EXTRACT_HOST_REGEX =  /^[a-zA-Z]{3,5}:\/(\/[a-zA-Z0-9_.:-]+)*(?=\/api$)/g  //  /^[a-zA-Z]{3,5}:\/{2}[a-zA-Z0-9_.:-]+/

export const CLEAN_PATH_REGEX =  /^([a-zA-Z]{3,5}:\/{2}[a-zA-Z0-9_.:-]+\/)|(^\/*)/

export const LOCALE_ENGLISH = 'en'
export const LOCALE_RUSSIAN = 'ru'
export const LOCALES = [
    LOCALE_ENGLISH, LOCALE_RUSSIAN,
]

export const REGEX_LOCALE = /\/((en)|(ru))\//;

// meta

export const META_IMAGE_WIDTH = 600;
export const META_IMAGE_HEIGHT = 800;

export const META_IMAGE_DESIGN_SIZE = 750;

// navbar
export {default as ICON_LOCALE_EN} from "@/export/assets/img/united-kingdom.png";
export {default as ICON_LOCALE_RU} from "@/export/assets/img/russia.png";


// system
export const COPYRIGHT_TEXT_PREFIX = '&ensp;|&ensp;'
export const COPYRIGHT_TEXT_EN = `Vadim Voronov 2022${COPYRIGHT_TEXT_PREFIX}Android Developer`;
export const COPYRIGHT_TEXT_RU = `Воронов Вадим 2022${COPYRIGHT_TEXT_PREFIX}Android разработчик`;


// contacts
export const CONTACT_REGEX_LONG = /main\.\w+\.about|contacts/

export const CONTACT_REGEX_TEMPLATE = '***${key}***'
export const CONTACT_REGEX_KEYS = ['phone', 'linkedin', 'github', 'hh', 'play', 'email', 'whatsup', 'telegram']
export const CONTACT_PROTECTED_MAIL = '<a href="#" class="protect-mail title-14-0-black green tall strong" data-name="info1" data-domain="example" data-tld="org" @click="redirect"></a>'

export const CONTACT_REGEX_MAIL_NAME = /^.+(?=@)/g
export const CONTACT_REGEX_MAIL_DOMAIN = /(?<=@).+(?=\.\w+$)/g
export const CONTACT_REGEX_MAIL_EXT = /(?<=\.)\w+$/g
export const CONTACT_REGEX_MAIL = {
    name: CONTACT_REGEX_MAIL_NAME,
    domain: CONTACT_REGEX_MAIL_DOMAIN,
    ext: CONTACT_REGEX_MAIL_EXT
}

export const CONTACT_EMAIL_TYPE_TEXT = 1
export const CONTACT_EMAIL_TYPE_ICON = 2
export const CONTACT_EMAIL_TYPE_LINK = 3

export const CONTACT_REGEX_PHONE_COUNTRY = /(?<=^\+)\d{1,5}(?=[.(])/g
export const CONTACT_REGEX_PHONE_CITY = /((?<=[.(])\d{3,7}(?=[.)]))/g
export const CONTACT_REGEX_PHONE_NUMBER_FIRST = /(?<=[.)])\d{3}(?=[.-])/g
export const CONTACT_REGEX_PHONE_NUMBER_LAST = /(?<=\.)\d{4}$/g
export const CONTACT_REGEX_PHONE_NUMBER_LAST_START = /(?<=-)\d{2}(?=-)/g
export const CONTACT_REGEX_PHONE_NUMBER_LAST_END = /(?<=-)\d{2}$/g
export const CONTACT_REGEX_PHONE_TYPE_EN = /^\+\d{1,5}\.\d{3,7}\.\d{3}\.\d{4}$/g
export const CONTACT_REGEX_PHONE_TYPE_RU = /^\+\d{1,5}\(\d{3,7}\)\d{3}-\d{2}-\d{2}$/g

export const CONTACT_REGEX_PHONE = {
    country: CONTACT_REGEX_PHONE_COUNTRY,
    city: CONTACT_REGEX_PHONE_CITY,
    first: CONTACT_REGEX_PHONE_NUMBER_FIRST,
    last: CONTACT_REGEX_PHONE_NUMBER_LAST,
    start: CONTACT_REGEX_PHONE_NUMBER_LAST_START,
    end: CONTACT_REGEX_PHONE_NUMBER_LAST_END,
}

export const CONTACT_PHONE_TYPE_EN= 1
export const CONTACT_PHONE_TYPE_RU = 2



export const RECORD_TYPE_SYSTEM = 1;
export const RECORD_TYPE_WORK = 2;
export const RECORD_TYPE_ABOUT = 3;
export const RECORD_TYPE_CONTACTS = 4;

export const CONTAINER_TYPE_TOP = 1;
export const CONTAINER_TYPE_TABLE_APP = 12;
export const CONTAINER_TYPE_HEADER = 2;
export const CONTAINER_TYPE_TABLE_3 = 3;
export const CONTAINER_TYPE_TEXT = 32;
export const CONTAINER_TYPE_LEFT = 4;
export const CONTAINER_TYPE_LEFT_SINGLE = 42;
export const CONTAINER_TYPE_RIGHT = 5;
export const CONTAINER_TYPE_RIGHT_SINGLE = 52;
export const CONTAINER_TYPE_CENTER = 6;
export const CONTAINER_TYPE_COLLAGE = 7;
export const CONTAINER_TYPE_ABOUT_4 = 8;
export const CONTAINER_TYPE_ABOUT_3 = 9;
export const CONTAINER_TYPE_ABOUT_CONTACTS = 10;

export const CONTAINER_MAX_ROWS_4 = 4;
export const CONTAINER_MAX_ROWS_3 = 3;


export const ROW_TYPE_TOP = 1;
export const ROW_TYPE_TEXT = 32;
export const ROW_TYPE_COLLAGE = 7;
export const ROW_TYPE_ABOUT = 8;

// mail template
export const DOCTYPE = '<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">'
export const EMAIL_TO = 'support@vmti.ru'
export const EMAIL_SUBJECT = 'from '

export const EMAIL_FROM_EMAIL = 'sender_20220414@vmti.ru'
export const EMAIL_FROM_NAME = 'Portfolio support'

export const EMAIL_TEMPLATE_NAME = '***name***'
export const EMAIL_TEMPLATE_LAST = '***last***'
export const EMAIL_TEMPLATE_EMAIL = '***email***'
export const EMAIL_TEMPLATE_SUBJECT = '***subject***'
export const EMAIL_TEMPLATE_MESSAGE = '***message***'

export const EMAIL_TEMPLATE =
    `Support Form Content:\r\n\r\n`
    + `First name: ${EMAIL_TEMPLATE_NAME}, Last name: ${EMAIL_TEMPLATE_LAST} \r\n\r\n`
    + `Email address: ${EMAIL_TEMPLATE_EMAIL}\r\n\r\n`
    + `Subject: ${EMAIL_TEMPLATE_SUBJECT}\r\n\r\n`
    + `Message:\r\n\r\n${EMAIL_TEMPLATE_MESSAGE}\r\n\r\n`
    + `Message sent: ${new Date().toLocaleString()}`


// table-app

export const MAIN_APP_BUTTON_MARGIN = 20;       // container-007 table-app margin

export const TABLE_APP_MEDIA_MD = 768
export const TABLE_APP_MEDIA_LG = 992

export const TABLE_APP_SECTION_PADDING = 10     // body padding
export const TABLE_APP_SECTION_PADDING_MD = 110 //

export const TABLE_APP_SECTION_MAX_WIDTH = 1140 // body max-width
export const TABLE_APP_SCALE = 2
export const TABLE_APP_SCALE_LG = 3

export const TABLE_APP_ROUND = 2 // для коротких компенсация
export const TABLE_APP_DELTA = 0 // для режима 1140 компенсация

export const TABLE_APP_SINGLE_LIMIT = 3;

// admin


// main

export const SEND_MODE_POST = 'post';
export const SEND_MODE_PUT = 'put';
export const SEND_MODE_GET = 'get';

