<template>
  <div class="d-inline-flex" v-if="type===CONTACT_EMAIL_TYPE_TEXT">
    <p v-html="prefix"></p>
    <p class="protect-mail"
       :data-name="extract(CONTACT_REGEX_MAIL['name'],email)"
       :data-host="extract(CONTACT_REGEX_MAIL['domain'],email)"
       :data-ext="extract(CONTACT_REGEX_MAIL['ext'], email)"></p>
  </div>
  <a href="mailto:me" target="_blank" @click="redirect" v-else-if="type===CONTACT_EMAIL_TYPE_ICON">
    <i class="fas fa-envelope"></i>
  </a>
  <a href="mailto:me" class="protect-mail title-14-0-black tall strong"
     :data-name="extract(CONTACT_REGEX_MAIL['name'],email)"
     :data-host="extract(CONTACT_REGEX_MAIL['domain'],email)"
     :data-ext="extract(CONTACT_REGEX_MAIL['ext'], email)"
     @click="redirect" v-else-if="type===CONTACT_EMAIL_TYPE_LINK"></a>
</template>

<script>
import {ref, computed, onMounted, watch, inject} from 'vue'
import {useStore} from 'vuex'
import {useRouter} from 'vue-router'
import {
  CONTACT_EMAIL_TYPE_TEXT,
  CONTACT_EMAIL_TYPE_ICON,
  CONTACT_EMAIL_TYPE_LINK,
  CONTACT_REGEX_MAIL,
  EMPTY_STRING,
  COPYRIGHT_TEXT_PREFIX,
} from "@/use/constants";
import {useMainUtil} from "@/use/main-modules";

export default {
  name: "TheProtectedEmail",
  props: {
    email: {
      type: String,
      required: false,
      default: EMPTY_STRING,
    },
    type: {
      type: Number,
      required: false,
      default: CONTACT_EMAIL_TYPE_TEXT
    }
  },
  setup(props) {

    const store = useStore()
    const router = useRouter()

    const loading = ref('true')
    const prefix = COPYRIGHT_TEXT_PREFIX

    const p_email = ref(null)

    onMounted(async () => {
      try {

      } catch (e) {
        console.dir(e)
      }

      loading.value = false
    })

    const redirect = async () => {
      const url = 'mailto:' + props.email;
      window.open(url)
    }

    const {extract} = useMainUtil()

    return {
      loading, CONTACT_REGEX_MAIL, CONTACT_EMAIL_TYPE_TEXT, CONTACT_EMAIL_TYPE_ICON, CONTACT_EMAIL_TYPE_LINK,
      extract, redirect, prefix,
    }
  },
  components: {},
}
</script>

<style scoped>

</style>