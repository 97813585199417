import {EMPTY_STRING} from "@/use/constants";
import store from "@/store";

export const GET_IN_TOUCH = 'get_in_touch'

const TRANSLATE_MESSAGES = {
    en: {
        get_in_touch: 'GET IN TOUCH',
        contact_fill: 'Fill out the form below.',
        contact_name: 'Name *',
        contact_first: 'First Name',
        contact_last: 'Last Name',
        contact_email: 'Email Address *',
        contact_subject: 'Subject *',
        contact_message: 'Message *',
        contact_submit: 'Submit',
        contact_sent: 'Message has been sent successfully.',
        contact_thanks: 'Thank you!',

        form_title:'Contact form',
        form_header: 'Portfolio contact form:',
        form_sent: 'Message sent: ',
        page_work: 'Vadim Voronov | Android Developer',
        page_about: 'About - Vadim Voronov',
        page_contact: 'Contacts - Vadim Voronov',
        page_design: 'Vadim Voronov - %s',

        main_work: 'WORK',
        main_about: 'ABOUT',
        main_contacts: 'CONTACTS',


    },
    ru: {
        get_in_touch: '&emsp;НАПИСАТЬ&emsp;', // &nbsp; &ensp; &emsp;
        contact_name: 'ФИО *',
        contact_first: 'Имя',
        contact_last: 'Фамилия',
        contact_email: 'Email адрес *',
        contact_subject: 'Тема *',
        contact_message: 'Сообщение *',
        contact_submit: 'Отправить',
        contact_sent: 'Сообщение успешено отправлено.',
        contact_thanks: 'Спасибо!',

        form_title:'Форма обратной связи',
        form_header: 'Портфолио форма обратной связи:',
        form_sent: 'Сообщение отправлено: ',

        page_work: 'Воронов Вадим | Android-разработчик',
        page_about: 'О себе - Воронов Вадим',
        page_contact: 'Контакты - Воронов Вадим',
        page_design: 'Воронов Вадим - %s',

        main_work: 'ПРОЕКТЫ',
        main_about: 'О СЕБЕ',
        main_contacts: 'КОНТАКТЫ',

    },
}

export default {
    install(app, options) {

        const translate = (key) => {
            const locale = store.getters['main/locale']
            if (!key || !locale || !TRANSLATE_MESSAGES[locale][key]) {
                return EMPTY_STRING
            }
            return TRANSLATE_MESSAGES[locale][key];
        }

        app.provide('translate',translate)
    },

}


