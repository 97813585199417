
export function useErrorMessage(error){
    if (error == null) {
        return {
            error: 'unknown error',
            code: 400
        };
    }
    return {
        error: error.message,
        code: error?.response?.status ? error.response.status : 400
    }
}

export function useSuccessMessage(message, code = null) {
    if (message == null) {
        return {
            message: 'Operation success',
            code: code
        };
    }
    return {
        message: message,
        code: code
    }
}
