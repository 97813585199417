<template>
  <!--  import instruction-->
  <!--  copy and paste div.container-->

  <nav class="navbar navbar-light navbar-expand-sm fixed">
    <div class="container-fluid"><a class="navbar-brand d-sm-flex align-items-sm-center" href="#"><img class="img-fluid" :src="brand" alt="Brand Icon"></a><button data-bs-toggle="collapse" class="navbar-toggler" data-bs-target="#navcol"><span class="visually-hidden">Toggle navigation</span><span class="navbar-toggler-icon"></span></button>
      <div class="collapse navbar-collapse" id="navcol">
        <ul class="navbar-nav ms-auto">
          <li class="nav-item d-sm-flex align-items-sm-center">
            <router-link class="nav-link" :to="`/main/${locale}/work`">{{translate('main_work')}}</router-link></li>
          <li class="nav-item d-sm-flex align-items-sm-center">
            <router-link class="nav-link" :to="`/main/${locale}/about`">{{translate('main_about')}}</router-link></li>
          <li class="nav-item d-sm-flex align-items-sm-center">
            <router-link class="nav-link" :to="`/main/${locale}/contacts`">{{translate('main_contacts')}}</router-link></li>
          <li class="nav-item dropdown no-arrow">
            <div class="nav-item dropdown no-arrow"><a class="dropdown-toggle nav-link" aria-expanded="false" data-bs-toggle="dropdown" href="#"><img class="img-locale" :src="icon" width="" height="" alt="Locale Icon"></a>
              <div class="dropdown-menu shadow dropdown-menu-end animated--grow-in">
                <a :href="destEn" class="dropdown-item" :class="{'active':isEnglish}" @click="update('en')">English</a>
                <a :href="destRu" class="dropdown-item" :class="{'active':isRussian}" @click="update('ru')">Русский</a>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>


</template>

<script>
import {ref, computed, onMounted, watch, inject} from 'vue'
import {useStore} from 'vuex'
import {useRoute, useRouter} from 'vue-router'
import {LOCALE_ENGLISH, LOCALE_RUSSIAN, REGEX_LOCALE, ICON_LOCALE_EN, ICON_LOCALE_RU} from "@/use/constants";

export default {
  name: "TheMainNavbar",
  props: {
    brand: {
      type: String,
      required: true,
    }
  },
  setup(props) {

    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    const translate = inject('translate')

    const locale = computed(() => store.getters['main/locale'])
    const icon = computed(() => store.getters['main/locale'] === LOCALE_ENGLISH ? ICON_LOCALE_EN : ICON_LOCALE_RU)
    const isEnglish = computed(() => LOCALE_ENGLISH === store.getters['main/locale'])
    const isRussian = computed(() => LOCALE_RUSSIAN === store.getters['main/locale'])

    const destEn = ref('/main/en')
    const destRu = ref('/main/ru')

    onMounted(async () => {
      try {
        update(route?.meta?.locale)

      } catch (e) {
        console.dir(e)
      }
    })

    const generate = (locale) => {
      const path = route.path.replace(/(?<=\/main\/)\w+(?=\/)/,locale)
      dest.value = path;
      console.log('nv-generate', path)
      return dest.value
    }

    const update = (locale) => {
      destEn.value = route.path.replace(/(?<=\/main\/)\w+(?=\/)/,LOCALE_ENGLISH)
      destRu.value = route.path.replace(/(?<=\/main\/)\w+(?=\/)/,LOCALE_RUSSIAN)

      store.commit('main/locale', locale)

    }


    return {
      locale, icon, isEnglish, isRussian, destEn, destRu,
      update, generate, translate,
    }
  },
  components: {},
}
</script>

<style scoped>
.local {
  background-color: #f0f0f0;
  /*max-width: 1040px;*/
  margin: 0 auto;
}
</style>