<template>
  <div v-if="message" @click="close">
    <h3>App Message: {{ message.type }}</h3>
    <p>Content of message:{{ message.value }} </p>
  </div>
</template>

<script>
import {ref, computed, onMounted, watch, inject} from 'vue'
import {useStore} from 'vuex'
import {useRouter} from 'vue-router'

export default {
  name: "TheMessage",
  setup() {

    const store = useStore()
    const router = useRouter()

    const message = computed(() => store.getters['message'])

    onMounted(async () => {
      try {

      } catch (e) {
        console.dir(e)
      }

    })

    const close = ()=>{
      store.commit('message',null)
    }
    return {
      message,
      close,
    }
  },
  components: {},
}
</script>

<style scoped>

</style>