<template>
  <div id="admin">
  <the-spinner v-if="loading"></the-spinner>
  <router-view :key="route.fullPath"></router-view>
    <!--  ВНИМАНИЕ. Здесь key=routeFullPath делает перезагрузку дочернего Admin-->
    <!--   и проверку авторизации у backend,-->
    <!--  ВНИМАНИЕ. Этот способ заставляет LayoutAdmin перезагружаться на каждый child-->
    <!--  НО ПО ДРУГОМУ авторизацию у backend не проверить-->
    <!--  Если нужно перезагружать редко то лучше использовать :key="locale"-->

  </div>

</template>

<script>
import {ref, computed, onMounted, watch, inject} from 'vue'
import {useStore} from 'vuex'
import {useRoute, useRouter} from 'vue-router'
import TheMessage from "../public/app/TheMessage";
import TheSidebar from "../public/app/admin/TheAdminSidebar";
import TheNavbar from "../public/app/admin/TheAdminNavbar";
import TheSpinner from "@/components/public/app/TheSpinner";

export default {
  name: "LayoutAdmin",
  setup() {

    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    const loading = computed(()=> store.getters['admin/loading'])

    onMounted(async () => {
      try {
        document.body.className = route.meta?.class

      } catch (e) {
        console.dir(e)
      }

    })
    return {
      loading, route,
    }
  },
  components: {
    TheMessage, TheSidebar, TheNavbar, TheSpinner,
  },
}
</script>

<style scoped>

</style>