import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import translate from './plugins/translate_plugin'
import {createHead} from "@vueuse/head";
import metaInfo from "@/plugins/meta_info_plugin";

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.min"

// admin
import "@/export/assets/css/styles-admin.css"               // <body> clashes with styles-main
import "@/export/assets/css/bootstrap-admin.css"

// main
import "@/export/assets/css/Nunito.css"
import "@/export2/assets/fonts/fontawesome-all.min.css"

import "@/export2/assets/css/styles-main.css"               // <body> overrides admin
import "@/export2/assets/css/section-001.css"
import "@/export2/assets/css/section-002.css"
import "@/export2/assets/css/section-007.css"
import "@/export2/assets/css/container-001.css"
import "@/export2/assets/css/container-002.css"
import "@/export2/assets/css/container-003.css"
import "@/export2/assets/css/container-004.css"
import "@/export2/assets/css/container-006.css"
import "@/export2/assets/css/container-007.css"

import "@/export3/assets/css/section-work.css"
import "@/export3/assets/css/container-work-001.css"
import "@/export3/assets/css/container-work-002.css"

import "@/export3/assets/css/section-about.css"
import "@/export3/assets/css/container-about.css"


const head = createHead()

createApp(App)
    .use(store)
    .use(router)
    .use(translate)
    .use(head)
    .use(metaInfo)
    .mount('#app')
