import {useAxios} from "@/use/axios-helper";
import {useErrorMessage} from "@/use/message";


export default {
    namespaced: true,

    state: () => {
        return {}
    },
    getters: {},
    mutations: {},
    actions: {
        async upload({dispatch, commit}, payload) {
            try {
                const data = new FormData();
                data.append('image', payload.file);
                data.append('path', payload.path);

                const response = await useAxios().post('upload', data)
                return response.data;
            } catch (e) {
                return useErrorMessage(e)
            }
        },
        async get({dispatch, commit, getters, rootGetters}, url) {
            try {
                const response = await useAxios().get(url)
                return response.data;
            } catch (e) {
                return useErrorMessage(e)
            }
        },
        async post({dispatch, commit}, payload) {
            try {
                const response = await useAxios().post(payload.url, payload.data)
                return response.data;
            } catch (e) {
                return useErrorMessage(e)
            }
        },
        async put({dispatch, commit}, payload) {
            try {
                const response = await useAxios().put(payload.url, payload.data)
                return response.data;
            } catch (e) {
                return useErrorMessage(e)
            }
        },
        async delete({dispatch, commit}, url) {
            try {
                const response = await useAxios().delete(url)
                return response.data;
            } catch (e) {
                return useErrorMessage(e)
            }
        },

    }
}