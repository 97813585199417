<template>
  <the-message></the-message>
  <the-main-navbar :brand="brand"></the-main-navbar>
  <router-view :records="records"></router-view>
  <the-main-footer :copyright="copyright" :contact="contact"></the-main-footer>

</template>

<script>
import {ref, computed, onMounted, watch, inject} from 'vue'
import {useStore} from 'vuex'
import {useRoute, useRouter} from 'vue-router'
import TheMainNavbar from "@/components/public/app/main/TheMainNavbar";
import TheMessage from "@/components/public/app/TheMessage";
import TheMainFooter from "@/components/public/app/main/TheMainFooter";

import {
  CONTACT_REGEX_LONG,
  EMPTY_STRING,
  LOCALE_ENGLISH,
  RECORD_TYPE_CONTACTS,
  RECORD_TYPE_SYSTEM,
} from "@/use/constants";
import {useMainLoading, useMainUtil} from "@/use/main-modules";
import ICON_BRAND from "@/export/assets/img/compass.png";

const {COPYRIGHT_TEXT_EN, COPYRIGHT_TEXT_RU} = require("@/use/constants");

export default {
  name: "Main",
  setup() {

    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    const records = ref({})
    const record = ref({})

    const brand = ref(ICON_BRAND)
    const copyright = ref(EMPTY_STRING)
    const contact = ref({})

    onMounted(async () => {
      try {
        loading(true)
        const response = await store.dispatch('request/get',
            `records?locale=${locale.value}`)

        if (!response.error && response.data?.length) {
          records.value = response.data.reduce((array, item) => ({...array, [item.type]: item}), {})

          if(records.value[RECORD_TYPE_SYSTEM]?.url) {
            brand.value = path(records.value[RECORD_TYPE_SYSTEM]?.url)
          }
          if(records.value[RECORD_TYPE_SYSTEM]?.title) {
           copyright.value  = records.value[RECORD_TYPE_SYSTEM]?.title
          }
          if(records.value[RECORD_TYPE_CONTACTS]) {
            contact.value = records.value[RECORD_TYPE_CONTACTS]
          }
        }

      } catch (e) {
        console.dir(e)
      }

      loading(false)
    })

    const {loading, locale, path, } = useMainUtil()

    return {
      route, brand, copyright, records, contact,
    }
  },
  components: {
    TheMainNavbar, TheMessage, TheMainFooter,
  },
}
</script>

<style scoped>

</style>
