import store from "@/store";
import {computed, ref} from "vue";
import {
    CLEAN_PATH_REGEX,
    DEFAULT_TABLE_TITLE_LENGTH,
    DEFAULT_TABLE_TITLE_REGEX,
    EXTRACT_HOST_REGEX,
    LOCALE_ENGLISH,
    LOCALES, UPLOAD_HOST_REGEX
} from "@/use/constants";

// constants
export const ROOT_BACK = '/admin/work/';
export const PAGINATE_PAGE_SIZE = 100;


export const SEND_MODE_INPUT = 'input';
export const SEND_MODE_CREATE = 'create';
export const SEND_MODE_UPDATE = 'update';

const SEND_MODES = [
    SEND_MODE_INPUT, SEND_MODE_CREATE, SEND_MODE_UPDATE,
]

export const validation = {
    locale: (value) => {
        for (const locale of LOCALES) {
            if (locale === value) {
                return true
            }
        }
        return false
    }
}

export function useCreate() {

    const items = ref([])
    const record = ref(null)
    const mode = ref(null)

    const sorted = computed(() => items.value.sort((a, b) => a.order - b.order))
    const active = computed(() => mode.value === SEND_MODE_CREATE || mode.value === SEND_MODE_UPDATE)
    const empty = computed(() => mode.value === SEND_MODE_INPUT)
    const added = computed(() => !!record.value?.id)

    const get = () => {
        return mode.value
    }
    const post = () => {
        return mode.value === SEND_MODE_CREATE;
    }

    const put = () => {
        return mode.value === SEND_MODE_UPDATE;
    }

    const set = (value) => {
        if (SEND_MODES.includes(value)) {
            mode.value = value;
        }
    }

    const input = () => {
        set(SEND_MODE_INPUT)
    }

    const update = () => {
        set(SEND_MODE_UPDATE)
    }

    const create = () => {
        set(SEND_MODE_CREATE)
    }

    const send = async (url, data) => {
        if (post()) {
            return await store.dispatch(`request/post`, {
                'url': url,
                'data': data,
            })
        } else if (put()) {
            return await store.dispatch(`request/put`, {
                'url': `${url}/${record.value?.id}`,
                'data': data,
            })
        } else {
            return null
        }
    }

    const extract = (value, placeholder = 'empty', length = DEFAULT_TABLE_TITLE_LENGTH) => {
        if (!value) {
            return placeholder
        }
        const str = value.replaceAll(DEFAULT_TABLE_TITLE_REGEX, '')
        return (str.length > length) ? `${str.substr(0, length - 1)}&hellip;` : str;
    }

    const clean = (url) => {
        if (!url) return ''
        return  url.replace(CLEAN_PATH_REGEX, '')
    }

    const path = (url) => {
        if (!url) return ''
        const array = process.env.VUE_APP_URL.match(EXTRACT_HOST_REGEX)
        if (!array || array.length === 0) return ''
        const host = array[0]
        const path = url.replace(CLEAN_PATH_REGEX, '')
        return host + '/' + path
    }


    const confirmed = (value) => {
        return confirm(`You want to delete ${value}. Are you sure?`);
    }

// processing
    const preprocess = (values) => {
        // padding
        if(process.env.VUE_APP_PADDING && process.env.VUE_APP_PADDING.toLowerCase()==='true'){
            if(!values || !values.description){
                return
            }
            values.description = values.description.replace(/(\s*<br>\s*)+$|(\s*)$/,"")+ "\n!!\n!!\n"
        }
    }

    const postprocess = (s) => {
        return s
            // 4x
            .replace(/_____/g, "&emsp;")
            .replace(/____/g, "&ensp;")
            .replace(/__/g, "&nbsp;")
            .replace(/@@@@/g, "\<small\>\<i\>")
            .replace(/####/g, "\</i\>\</small\>")
            // 3x
            .replace(/@@@/g, "\<a href=\"")
            .replace(/\s*###\s*/g, "\" target=\"_blank\" \n\>")
            .replace(/\$\$\$/g, "\</a\>")
            .replace(/@@U/g, "\<u\>")
            .replace(/U##/g, "\</u\>")
            .replace(/@@I/g, "\<i\>")
            .replace(/I##/g, "\</i\>")
            .replace(/@@RR/g, "\<small\>\<i\>\<span style=\"color:red\"\>\<b\>")
            .replace(/RR##/g, "\</b\>\</span\>")
            .replace(/RR\$\$/g, "\</i\>\</small\>")
            .replace(/@@RI/g, "\<i\>\<span style=\"color:red\"\>\<b\>")
            .replace(/RI##/g, "\</b\>\</span\>\</i\>")
            .replace(/@@R/g, "\<span style=\"color:red\"\>")
            .replace(/R##/g, "\</span\>")
            .replace(/~~~/g, "\<u\>")
            .replace(/!!!/g, "\</u\>")

            // 2x
            .replace(/!!/g, "\<br\>")
            .replace(/@@</g, "&lt;")
            .replace(/@@>/g, "&gt;")
            .replace(/@@\/\//g, "\<!--")
            .replace(/\/\/@@/g, "--\>")
            .replace(/@@/g, "\<b\>")
            .replace(/##/g, "\</b\>")
    }

    return {
        active, empty, record, items, sorted, added,
        create, update, input, get, post, put, send, extract, path, clean, confirmed,
        preprocess, postprocess,
    }

}

export function useUpload(url) {
    const upload = (value) => {
        url.value = value;
    }
    return {
        upload,
    }
}

export function useAdminLoading() {
    return (value) => {
        store.commit('admin/loading', value)
    }
}

export function useAdminUtil() {
    const locale = computed(() => store.getters['admin/locale'] || LOCALE_ENGLISH)

    const loading = (value) => {
        store.commit('admin/loading', value)
    }

    return {
        locale, loading
    }
}


export function useLoader(name, value) {
    store.commit(`${name}/loading`, value)
}
