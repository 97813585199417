<template>
  <div id="admin">
    <the-spinner v-if="loading"></the-spinner>
    <router-view></router-view>
  </div>

</template>

<script>
import {ref, computed, onMounted, watch, inject} from 'vue'
import {useStore} from 'vuex'
import {useRoute, useRouter} from 'vue-router'
import TheMessage from "../public/app/TheMessage";
import TheSpinner from "../public/app/TheSpinner";

export default {
  name: "LayoutAuth",
  setup() {

    const store = useStore()
    const router = useRouter()
    const route = useRoute()

    const loading = computed(() => store.getters['auth/loading'])

    onMounted(async () => {
      try {
        document.body.className = route.meta?.class

      } catch (e) {
        // console.dir(e)
      }
    })

    return {
      loading,
    }
  },
  components: {
    TheMessage, TheSpinner,

  },
}
</script>

<style scoped>

</style>