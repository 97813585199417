import {useSuccessMessage} from "@/use/message";
import {useAxios} from "@/use/axios-helper";
import {useErrorMessage} from "@/use/message";

export default {

    namespaced: true,
    state: () => {
        return {
            user: null,
            loading: false,
        }
    },
    getters: {
        user(state) {
            return state.user;
        },
        loading(state) {
            return state.loading;
        }
    },
    mutations: {
        user(state, user) {
            state.user = user;
        },
        loading(state, value) {
            state.loading = value
        }
    },
    actions: {
        async logout({dispatch, commit, getters, rootGetters}) {
            try {
                await useAxios().post('logout', {})
                return useSuccessMessage('Logout success')
            } catch (e) {
                console.dir(e);
                return useErrorMessage(e);
            }
        },
        async login({dispatch, commit,}, data) {
            try {
                const response = await useAxios().post(`${process.env.VUE_APP_URL}/login`, data);
                return response.data;
            } catch (e) {
                console.dir(e);
                return useErrorMessage(e);
            }
        },
        async register({commit, dispatch, getters}, data) {
            try {
                const response = await useAxios().post(`${process.env.VUE_APP_URL}/register`, data);
                return response.data;
            } catch (e) {
                console.dir(e);
                return useErrorMessage(e)
            }
        }
    }

}