<template>
  <div id="main">
    <the-spinner v-if="loading"></the-spinner>
    <router-view></router-view>
  </div>
</template>

<script>
import {computed, onMounted, ref, watch} from 'vue'
import {useStore} from "vuex";
import {useRoute} from 'vue-router'
import TheMessage from "@/components/public/app/TheMessage";
import TheSpinner from "@/components/public/app/TheSpinner";

export default {
  name: "LayoutMain",
  setup() {
    const store = useStore()
    const route = useRoute()

    const loading = computed(() => store.getters['main/loading']);

    onMounted(async () => {
      try {
        document.body.className = route.meta?.class;

      } catch (e) {
        // console.dir(e)
      }
    })

    return {
      loading, route,
    }
  },
  components: {
    TheMessage, TheSpinner,
  },
}
</script>

<style scoped>

</style>