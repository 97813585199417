import {validation,} from "@/use/admin-module";
import {LOCALE_ENGLISH,} from "@/use/constants";

export default {
    namespaced: true,

    state: () => {
        return {
            loading: false,
            locale: null,
        }
    },
    getters: {
        loading(state) {
            return state.loading
        },
        locale(state) {
            if (!validation.locale(state.locale)) {
                return LOCALE_ENGLISH
            }
            return state.locale
        },
    },
    mutations: {
        loading(state, value) {
            state.loading = value;
        },
        locale(state, locale) {
            if (!validation.locale(locale)) {
                return
            }
            state.locale = locale
        },

    }
}