import {createStore} from 'vuex'
import auth from "@/store/auth";
import request from "@/store/request";
import main from "@/store/main";
import admin from "@/store/admin";

export default createStore({

    methods: {},
    state() {
        return {
            message: null,
        }
    },
    getters: {
        message(state) {
            return state.message
        }
    },
    mutations: {
        message(state,message){
            state.message = message
        }
    },
    actions: {},
    modules: {
        auth, request, main, admin,
    }
})
