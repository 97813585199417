import {computed, ref} from "vue";
import {useHead} from "@vueuse/head";
import store from "@/store";
import {EMPTY_STRING, META_IMAGE_HEIGHT, META_IMAGE_WIDTH} from "@/use/constants";

const META_TAGS = [
    "[property='og:title']",
    "[property='og:url']",
    "[property='og:image']",
    "[property='og:image:width']",
    "[property='og:image:height']",
    "[itemprop='name']",
    "[itemprop='url']",
    "[itemprop='thumbnailUrl']",
    "[itemprop='image']",
    "[name='twitter:title']",
    "[name='twitter:image']",
    "[name='twitter:url']",
    "[rel='image_src']",
]

export default {
    install(app, options) {

        const metadata = (title, url, image,
                          width = META_IMAGE_WIDTH,
                          height = META_IMAGE_HEIGHT,) => {

            if (!title || !url || !image || !width || !height) {
                return null
            }

            const metadata = ref({
                title, url, image, width, height,
            })

            for (const tag of META_TAGS) {
                document.querySelector(tag)?.remove()
            }
            useHead({
                // can be static or computed
                title: title,
                meta: [
                    {
                        property: 'og:title',
                        content: title,
                    },
                    {
                        property: 'og:url',
                        content: url,
                    },
                    {
                        property: 'og:image',
                        content: image,
                    },
                    {
                        property: 'og:image:width',
                        content: width,
                    },
                    {
                        property: 'og:image:height',
                        content: height,
                    },
                    {
                        itemprop: 'name',
                        content: title,
                    },
                    {
                        itemprop: 'url',
                        content: url,
                    },
                    {
                        itemprop: 'thumbnailUrl',
                        content: image,
                    },
                    {
                        itemprop: 'image',
                        content: image,
                    },
                    {
                        name: 'twitter:title',
                        content: title,
                    },
                    {
                        name: 'twitter:image',
                        content: image,
                    },
                    {
                        name: 'twitter:url',
                        content: url,
                    },
                ],
                link: [
                    {
                        rel: 'image_src',
                        href: image,
                    }
                ],

            })
            return metadata
        }

        app.provide('metadata', metadata)
    },
}

